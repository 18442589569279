import React, { useEffect, useState } from 'react';
import VGPHead from './components/VGPHead'
import Particles from './components/Particles'
import Nav from './components/Nav';
import Homepage from './components/Homepage'
import GamesMentioned from './components/GamesMentioned'
import SuggestAGame from './components/SuggestAGame';
import EpisodeArt from './components/EpisodeArt';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

const pageExceptions = ['/episode-art-gallery'];

function App() {
  const [galleryImages, setGalleryImages] = useState([]);
  const fetchRssContent = () => {
    const anchorurl = "https://anchor.fm/s/4689cd44/podcast/rss";
    fetch(anchorurl)
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
      .then((data) => {
        const items = data.querySelectorAll("item");
        const galleryImagesArray = [];
        items.forEach((item) => {
          let title = item.querySelectorAll("title")?.[0]?.textContent;
          let link = item.querySelectorAll("link")?.[0]?.textContent;
          let image = item.querySelectorAll("image")?.[0]?.outerHTML;
          image = image?.split('href="');
          image = image?.[1]?.split('"')
          image = image?.[0];
          if(!image.includes('nologo')){
            galleryImagesArray.push({
              href:image,
              title:title,
              link:link
            });
          }
        });
        setGalleryImages(galleryImagesArray)
      });
  };

  useEffect(fetchRssContent, []);
  const location = useLocation()
  const [isCurrentPageAnException, setIsCurrentPageAnException] = useState(false);
  useEffect(()=>{
    setIsCurrentPageAnException(pageExceptions.includes(location?.pathname));
    if(isCurrentPageAnException){
      document.body.classList.add('scroller');
    }
    else{
      document.body.classList.remove('scroller');
    }
  }, [location?.pathname,setIsCurrentPageAnException, isCurrentPageAnException]);
  return (
    <div className={`App`}>
      <VGPHead/>
      <Nav/>
      <div className="mainContent">
        <Routes>
          <Route path="*" element={<Homepage/>} />
          <Route exact path="/" element={<Homepage/>} />
          <Route exact path="/games-mentioned" element={<GamesMentioned/>} />
          <Route exact path="suggest-a-game" element={<SuggestAGame/>}/>
          <Route exact path="episode-art-gallery" element={<EpisodeArt galleryImages={galleryImages}/>}/>
        </Routes>
      </div>
      {!isCurrentPageAnException && <Particles/>}
    </div>
  );
}

export default App;
