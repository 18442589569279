import React, {useCallback, useEffect, useState} from 'react';
function GameSearch() {
  const [results, setResults] = useState([])
  const [allGames, setAllGames] = useState(null)
  const [searchTerms, setSearchTerms] = useState('');
  const [hasSearched, setHasSearched] = useState(false)
  const searchGames = useCallback(() => {
    let foundResults = []
    allGames.forEach((game)=>{
      const matches = game.games.filter(element => {
        if (element.indexOf(searchTerms) !== -1) {
          return true;
        }
        else{
          return false;
        }
      });
      if(matches.length > 0){
        foundResults.push(game)
      }
    })
    setResults(foundResults);
    setHasSearched(true);
  }, [searchTerms, allGames])

  const fetchRssContent = () => {
    const anchorurl = 'https://anchor.fm/s/4689cd44/podcast/rss'
    fetch(anchorurl)
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        const items = data.querySelectorAll("item");
        const descriptions = [];
        items.forEach((item)=>{
          let text = item.querySelectorAll("description")
          let title = item.querySelectorAll("title")?.[0]?.textContent
          let link = item.querySelectorAll("link")?.[0]?.textContent
          if(!text?.[0]?.textContent){
            return
          }
          let gamesMentioned = text?.[0]?.textContent.toLowerCase().split('<p>games mentioned:</p>')
          gamesMentioned = gamesMentioned?.[1]?.replace(/<\/?[^>]+(>|$)/g, "").split('\n').filter((game) => { return game !== '' });
          if(gamesMentioned){
            descriptions.push({episodeName: title, games:gamesMentioned, link:link})
          }
        })
        setAllGames(descriptions)
      })
  }

  useEffect(()=>{
    if(searchTerms.length > 2){
      searchGames()
    }
    else{
      setResults([])
    }
  },[searchTerms, searchGames])

  useEffect(fetchRssContent,[]) 

  return(
    <div className="gameSearch">
      <div className='prompt'>
        <p>Wanna see if we've covered a game that you like?</p>
        <input placeholder="toss the game in question in here" onChange={e => setSearchTerms(e.target.value.toLowerCase())} type="text"/>
      </div>
      {results.length > 0 &&
        <div>
            <p>Looks like that was mentioned in
              {results.length === 1 && (` this episode`)}
              {results.length > 1 && (` these episodes`)}
            </p>
            <ul>
            {results.map((game, index)=>{
            return <li key={index}><a href={game.link}>{game.episodeName}</a></li>
            })}
            </ul>
        </div>
      }
      {results.length === 0 && searchTerms.length > 2 && hasSearched &&
        <div>
          <p>Looks like we haven't talked about that one yet!</p>
        </div>
      }
    </div>
  )
}

export default GameSearch