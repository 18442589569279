import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/css/index.css';
import App from './App';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
const TRACKING_ID = "UA-233171347-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
root.render(
  <Router>
    <App />
  </Router>  
);
