import React, { useEffect, useState } from 'react'
import { send } from 'emailjs-com';
import Spinner from './Spinner'

function SuggestAGame(){
  const [toSend, setToSend] = useState({
    from_name: '',
    message: '',
    suggested_game: '',
  });
  const [isLoaded, setIsLoaded] = useState('')
  const [formState, setFormState] = useState('')

  useEffect(()=>{
    setIsLoaded('ease-in')
  },[])

  const onSubmit = (e) => {
    e.preventDefault();
    if(toSend.message.length > 0){
      setFormState('sending');
      send(
        'service_mtrupf3',
        'template_v2zrk6d',
        toSend,
        'nDkstveBf8cwsG75v'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormState('success')
      })
      .catch((err) => {
        console.log('FAILED...', err);
        setFormState('error')
      });
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return(
    <div className={`suggestion-box container ease ${isLoaded}`}>
      <div className={`row`}>
      <div className="col-3"></div>
        <div className="col-6">
          <h2>The Game Suggestion Box</h2>
          <form onSubmit={onSubmit}>
            <input
              type='text'
              name='from_name'
              placeholder='Your name (not required)'
              value={toSend.from_name}
              onChange={handleChange}
            />
            <input
              type='text'
              name='suggested_game'
              placeholder='The game you want us to play'
              value={toSend.suggested_game}
              onChange={handleChange}
            />
            <textarea
              type='text'
              name='message'
              placeholder='Any feelings you have about the game that you want us to include (also not required)'
              value={toSend.message}
              onChange={handleChange}
            />
            <button className="submit-button" type='submit'>
              {formState !== 'sending' && (
                <span>Submit</span>
              )}
              {formState === 'sending' && (
                <Spinner/>
              )}
            </button>
            {formState === 'success' &&(
              <h2>Message received, thanks for sending one in! 🌻</h2>
            )}
            {formState === 'error' &&(
              <h2>Looks like something, went wrong. Maybe try a refresh and try again?</h2>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default SuggestAGame