import React, {useState, useEffect} from 'react';
import discordIcon from '../images/discord.svg';
import twitterIcon from '../images/twitter.svg';
import emailIcon from '../images/email.png';
import podIcon from '../images/pod.png';

function Homepage(){
  const [isLoaded, setIsLoaded] = useState('')
  useEffect(()=>{
    setIsLoaded('ease-in')
  },[])
  return(
  <>
    <div className="container homepage">
      <div className={`row`}>
        <div className="col-3 desktop"></div>
        <div className="col-6">
          <h1 className={`ease ${isLoaded}`}>Video Game Podtimism</h1>
          <p className={`ease ${isLoaded}`}>The optimist's video game variety show, hosted by two best friends <a href="https://twitter.com/chaseallhart">Chase Allhart</a> and David Benavides</p>
        </div>
        <div className="col-3 desktop"></div>
        <div className="col-3 desktop"></div>
        <div className={`col-6 offset-2 spotify-player`}>
          <div className={`ease offset-1 ${isLoaded}`}>
            <iframe 
              src="https://open.spotify.com/embed/show/1ghYuJf0BNNyIFMJuNVPtr?utm_source=generator&t=0"
              width="100%" height="232" frameBorder="0" allowFullScreen="" opacity="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              title="spotifyplayer"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div className={`container links`}>
      <div className="row">
      <div className="col-3"></div>
        <div className="col-6 icon-list">
          <p className={`ease icon offset-2 ${isLoaded}`}><a href="https://discord.gg/xQ7ht3V"><img alt="icon for discord" src={discordIcon}></img></a></p>
          <p className={`ease icon offset-2 ${isLoaded}`}><a href="https://twitter.com/podtimism"><img alt="icon for twitter (sorry Elon we're not calling it X)" src={twitterIcon}></img></a></p>
          <p className={`ease icon offset-2 ${isLoaded}`}><a href="mailto:podtimism@gmail.com"><img alt="icon for email" src={emailIcon}></img></a></p>
          <p className={`ease icon offset-2 ${isLoaded}`}><a href="https://anchor.fm/podtimism"><img alt="icon for podcast host website" src={podIcon}></img></a></p>
        </div>
      </div>
    </div>
  </>
  )
}

export default Homepage