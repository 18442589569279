import React, {useState} from 'react'
import { Link } from "react-router-dom";

function Nav(){
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  return(
		<div className={`container nav`}>
			<div className="row">
				<div className="col-12 nav-column">
				<Link className={`VGP-logo ${mobileNavOpen ? 'open' : ''}`} to="/"><h1>VGP</h1></Link>
					<div className="nav-links desktop">
						<Link to="games-mentioned">Games mentioned</Link>
						<Link to="suggest-a-game">Suggest a game</Link>
						<Link to="episode-art-gallery">Episode art</Link>
					</div>
					<div className={`mobile-nav mobile ${mobileNavOpen ? 'open' : ''}`} onClick={()=>setMobileNavOpen(!mobileNavOpen)}>
            <span></span>
            <span></span>
            <span></span>
					</div>
          <div className={`mobile-nav-links ${mobileNavOpen ? 'open' : ''}`}>
            <Link onClick={()=>{setMobileNavOpen(false)}} to="games-mentioned">Games mentioned</Link>
						<Link onClick={()=>{setMobileNavOpen(false)}} to="suggest-a-game">Suggest a game</Link>
						<Link onClick={()=>{setMobileNavOpen(false)}} to="episode-art-gallery">Episode art</Link>
          </div>
				</div>
			</div>
	  </div>
	)
}

export default Nav