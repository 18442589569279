import React from "react";
function EpisodeArt({galleryImages}) {

  return (
    <div className="episodeArtGallery">
      <div className="gallery">
        {galleryImages.map((item, index) => {
          return (
          <a key={index} href={item.link} className="gallery-item">
            <img src={item.href} width="500" alt={`art for the episode titled: ${item.title}`}/>
            <p>{item.title}</p>
          </a>
          )
        })}
      </div>
    </div>
  );
}

export default EpisodeArt;
