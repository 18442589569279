import React, {useEffect, useState} from 'react'
import GameSearch from './GameSearch'

function GamesMentioned(){
  const [isLoaded, setIsLoaded] = useState('')
  useEffect(()=>{
    setIsLoaded('ease-in')
  },[])
    return(
      <div className={`ease ${isLoaded}`}>
        <div className="container">
          <div className={`row`}>
            <div className="col-12">
              <GameSearch/>
            </div>
          </div>
        </div>
      </div>
    )
}

export default GamesMentioned